<template>
    <div class="from_outer">
      <div>
        <el-form
          ref="formName"
          label-width="180px"
          :rules="rules"
          :model="formLabelAlign"
        >
          <el-row>
            <!-- <el-col :span="12">
              <el-form-item
                label="编码开始字段："
                prop="code_begin"
                :rules="[{ required: true, message: '编码开始字段' }]"
              >
                <el-input
                  placeholder="编码开始字段"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.code_begin"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="生成行车码数量：" prop="code_count">
                <el-input
                  placeholder="请输入生成行车码数量："
                  size="mini"
                  class="form-inp"
                  v-model.number="formLabelAlign.code_count"
                ></el-input>
              </el-form-item>
            </el-col>   
          </el-row>
        </el-form>
      </div>
  
      <div style="text-align: center">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
      <!-- 风险源标签 -->
    </div>
  </template>
  
  <script>
  import {
    carcodeSaveInfo,
    carcodeSave,
  } from "../../assets/request/api";
  import Bepartment from "../../components/Bepartment";
  export default {
    name: "carcoderedact",
    components: {
      Bepartment,
    },
    props: {
      dialog_show: {
        type: Boolean,
      },
    },
    data() {
      return {
        type_name: "保存",
        //要修改的id
        id: "",
        options: [],
        check_times: [],
        bepartment_Data: [],
        estimate_type: [],
        color_level: [],
        status_list: "",
        formLabelAlign: {
        //   code_begin: 0,
          code_count: 10,
        },
        rules: {
        },
        car_type_list:[]       
      };
    },
    created() {
    //   this.way_labelList();
    },
    methods: {
      way_show(id) {
        let froms = {};
        if (id) froms.id = id;
        carcodeSaveInfo(froms).then((res) => {
          let {
            info,
            car_type_list,
          } = res.data;
          this.car_type_list = car_type_list;
        //   if (id) {
        //     this.id = id;
        //     this.type_name = "修改";
        //     let { formLabelAlign } = this;
            // let {
            //   ower_name,
            //   ower_phone,
            //   idcard,
            //   car_no,
            //   irr_num,
            //   department_id,
            //   car_type,
            // } = info;
            // formLabelAlign.id = id;
            // formLabelAlign.ower_name = ower_name;
            // this.$refs.Bepartment.bepartment_List = department_id;
            // formLabelAlign.department_id = department_id;
            // formLabelAlign.ower_phone = ower_phone;
            // formLabelAlign.car_no = car_no;
            // formLabelAlign.irr_num = irr_num;
            // formLabelAlign.car_type = car_type;
            // formLabelAlign.idcard = idcard;
        //   }
        });
      },
    //   on_timeData() {
    //     let { timeData, formLabelAlign } = this;
    //     if (timeData == null) {
    //       formLabelAlign.begin_time = "";
    //       formLabelAlign.end_time = "";
    //     } else {
    //       formLabelAlign.begin_time = timeData[0];
    //       formLabelAlign.end_time = timeData[1];
    //     }
    //   },
      on_change(val) {
      },
    //   dataChange(event) {
    //     let events = event[event.length - 1];
    //     this.formLabelAlign.cate_id = events;
    //   },
      
      //点击保存
      on_preserve(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));

            carcodeSave(formLabelAlign).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                let that = this;
                that.$emit("way_tableData");
                setTimeout(function () {
                  that.$emit("update:refbool", false);
                }, 1000);
              }
            });
          } else {
            return false;
          }
        });
      },
      //返回
      route_go() {
        this.$emit("update:refbool", false);
      },
    },
   
  };
  </script>
  
  <style lang="less" scoped>
  .from {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .form-inp {
    width: 200px;
    height: 32px;
  }
  .pop-inp {
    width: 150px;
  }
  .tag-sty {
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  .selectlength {
    color: #515a6e;
    border: 1px solid #abdcff;
    background-color: #f0faff;
    padding: 10px 20px;
    font-size: 14px;
    margin: 20px 0;
    border: 5px;
    span {
      color: rgb(45, 140, 240);
    }
  }
  .search_button {
    display: flex;
    margin-bottom: 18px;
    flex-direction: column-reverse;
  }
  .botton-an {
    text-align: center;
    margin-top: 10px;
  }
  .pop-img {
    margin: 0 5px 10px 0;
    width: 40px;
    height: 40px;
  }
  .form-textarea {
    width: 400px;
    margin-bottom: 10px;
  }
  .execute {
    display: flex;
  }
  
  .execute /deep/ .el-input__inner {
    height: 32px;
    margin-top: 1px;
  }
  .el-col {
    height: 50px;
  }
  </style>